<template>
    <ValidationObserver ref="observe">
        <b-form>
            <!--form add student-->
            <ek-dialog
                ref="addDialog"
                title="إضافة طالب جديد"
                size="md"
                placeholder="ابحث عن مستخدم محدد"
                btnText="اضافة مستخدم"
                @ok="submit()"
                @close="reset"
                @search="setSearch"
            >
                <template #filter>
                    <div
                        class="d-flex justify-content-end align-items-center gap-5"
                    >
                        <ek-input-select
                            name="ufilter"
                            class="ek-select"
                            placeholder="حسب الجامعة"
                            v-model="university"
                            clearable
                            :options="allUniversities"
                        />
                        <ek-input-select
                            clearable
                            :options="allFactulties.faculties"
                            v-model="factualy"
                            class="ek-select"
                            placeholder="حسب الكلية"
                            name="ffilter"
                        />
                        <b-button
                            variant="outline-success"
                            @click="ExportStudentsToExcel"
                            >تصدير لإكسل
                        </b-button>
                        <!-- @click="ExportToExcel(subjectDetailDto.id)" -->
                        <!-- <v-select
            class="w-40"
              placeholder="حسب الجامعة"
              :options="allUniversities"
              v-model="university"
              item-value="id"
              @clear="uClear    "
          
              label="name"
              title="حسب الكلية"
            />

            <v-select
            class="w-40"
              placeholder="حسب الكلية"
              :options="allFactulties"
              v-model="factualy"
              item-value="id"
              label="name"
              title="حسب الكلية"
            /> -->
                    </div>
                </template>
                <template #body>
                    <ek-input-text
                        :rules="[
                            { type: 'required', message: 'اسم الطالب مطلوب' },
                        ]"
                        name="name"
                        placeholder="أدخل اسم الطالب"
                        label="اسم الطالب  "
                        v-model="studentDto.name"
                    ></ek-input-text>

                    <ek-input-text
                        type="number"
                        :rules="[
                            {
                                type: 'mobile',
                                message: 'يرجى ادخال رقم الموبايل صحيح',
                            },
                        ]"
                        name="phoneNumber"
                        placeholder="ادخل رقم الموبايل"
                        label=" رقم الموبايل"
                        v-model="studentDto.phoneNumber"
                    ></ek-input-text>

                    <ek-input-text
                        name="email"
                        placeholder="ادخل البريد الإلكتروني "
                        label=" البريد الإلكتروني "
                        v-model="studentDto.email"
                    ></ek-input-text>

                    <ek-input-text
                        :rules="[
                            { type: 'required', message: 'كلمة المرور مطلوبة' },
                            {
                                type: 'min:8',
                                message:
                                    'كلمة المرور يجب ان تحوي 8 محارف على الاقل',
                            },
                        ]"
                        name="password"
                        placeholder="أدخل  كلمة السر"
                        label="كلمة السر"
                        v-model="studentDto.password"
                    ></ek-input-text>

                    <ek-date-picker
                        v-model="studentDto.birthdate"
                        :rules="[
                            {
                                type: 'required',
                                message: 'تاريخ الميلاد مطلوب',
                            },
                        ]"
                        name="تاريخ الميلاد"
                        label="تاريخ الميلاد"
                        placeholder="ادخل تاريخ الميلاد"
                    ></ek-date-picker>

                    <div>
                        <label class="pb-1" style="font-size: 14px">
                            الجنس:</label
                        >
                        <b-form-radio-group
                            v-model="studentDto.gender"
                            :options="options"
                            value-field="item"
                            text-field="name"
                        ></b-form-radio-group>
                    </div>
                </template>
            </ek-dialog>
        </b-form>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    components: {
        ValidationObserver,
    },
    created() {
        this.getNamesUniversity();
    },
    data() {
        return {
            university: "",
            factualy: "",
            options: [
                { item: "Male", name: "ذكر" },
                { item: "Female", name: "انثى" },
            ],
        };
    },
    watch: {
        university(val) {
            this.factualy = "";
            this.getStudentsListByUniversity(val);
        },
        factualy(val) {
            this.getStudentsListByFactualy(val);
        },
    },

    computed: {
        ...mapState({
            studentDto: (state) => state.students.studentDto,
            departmentsSubjectsOptions: ({ courses }) =>
                courses.departmentsSubjectsOptions,
        }),
        ...mapGetters({ allUniversities: "allUinversities" }),
        allFactulties() {
            return (
                this.allUniversities.filter((uni) => {
                    return uni.id === this.university;
                })[0] ?? []
            );
        },
    },
    methods: {
        uClear() {
            console.log("selection cleard");
        },
        ...mapActions([
            "getStudentsList",
            "getStudentsListByFactualy",
            "getStudentsListByUniversity",
            "getNamesUniversity",
            "addStudents",
            "ExportAllStudentsToExcel",
        ]),

        //submit add student
        submit() {
            this.$refs.observe.validate().then((success) => {
                if (success) {
                    this.addStudents(this.studentDto);
                    this.$refs.addDialog.close();
                    this.$refs.observe.reset();
                }
            });
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: [
                    "name",
                    "phoneNumber",
                    "subjectsCount",
                    "subCount",
                    "isSub",
                ],
                query,
            });
        },

        reset() {
            this.$refs["observe"].reset();
            Object.assign(this.studentDto, {
                name: "",
                password: "",
                birthdate: "",
                email: "",
                phoneNumber: "",
                gender: "",
            });
        },

        ExportStudentsToExcel() {
            this.ExportAllStudentsToExcel();
        },
    },
};
</script>
<style>
#vs2__combobox {
    min-width: 123px;
    padding: 0;
}

#vs1__combobox {
    min-width: 123px;
    padding: 0;
}

.gap-5 {
    gap: 20px;
}

.w-40 {
    min-width: 160px;
    max-width: 160px;
}

.vs__selected-options {
    max-height: 32px;
    overflow: hidden;
    margin-right: 4px;
}

.vs__actions {
    position: relative;
}

.vs__actions button {
    position: absolute;
    left: -14px;
}

.ek-select {
    min-width: 140px;
    max-width: 140px;
}
</style>
